<template>
  <div class="about-container">
      <div class="part">
        <div class="title">{{ $t("message.about.title") }}</div>
        <div class="content">
          <div class="content_title">{{ $t("message.about.contentTitle") }}</div>
          <div class="content_list">
            {{ $t("message.about.contentItem") }} <br />
            {{ $t("message.about.contentItem1") }}<br />
            {{ $t("message.about.contentItem2") }}<br />
            {{ $t("message.about.contentItem3") }}<br />
          </div>

          <div class="content_title">{{ $t("message.about.contentTitle1") }}</div>
          <div class="content_list">
            {{ $t("message.about.email") }} <br />
            {{ $t("message.about.address") }}
          </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// import { reportWebVitals " from "@/utils/ga.js";
import Footer from "@/components/Footer.vue";

export default {
  name: "about",

  data() {
    return {};
  },
  components: {
    Footer,
  },

  mounted() {
    const locale = this.$i18n.locale;
    const messages = this.$i18n.messages;
    const desc = messages[locale].message.index.desc;
    this.descList = desc.split("/");
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.about-container {
  width: 100%;
  padding-top: 50px;
  .part {
    min-height: calc(100vh - 234px);
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    font-size: 48px;
    text-align: center;
    color: rgb(48, 43, 78);
    margin-bottom: 28px;
  }
  .content {
    // padding: 0 30px;
    padding-left: 40px;
    .content_title {
      font-size: 22px;
      margin-bottom: 11px;
      margin-top: 26px;
      font-weight: 600;
    }
    .content_list {
           font-size: 15px;
            color: rgba(0,0,0,.65);
            line-height: 24px;
            margin-bottom: 15px;
            overflow-wrap: break-word;
            text-align: justify;
    }
  }
}
</style>
